import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['cancelBtn', 'bundle',
    'addBtn', 'bundleModal', 'bundleModalTrigger', 'bundleRemover',
    'bundleQty', 'bundleType', 'bundleWeight', 'bundleWeightType', 'bundlePart', 'bundlePartQty',
    'rowParts', 'rowWeight', 'rowBundleQty', 'rowPiecesQty', 'rowNumbers', 'bundlePartNumber',
    'rowPartType', 'bundlePartType', 'typeOfDocumentation', 'dynamicBundleGroups', 'dynamicCustomerParts', 'existingConfiguration', 'hiddenCloseBtn'
  ]

  connect() {
    var modalId = 'Modal' + this.getRanHex(12);
    this.bundleModalTriggerTarget.dataset.bsTarget = '#' + modalId;
    this.bundleModalTarget.setAttribute('id', modalId);
    this.addBtnTarget.setAttribute('data-association-insertion-node', function(e){
      const element = e.closest('.bundle')
      return element.querySelector('.bundle_customer_parts')
    })
    let cocoonContainer = this.addBtnTarget.closest('.bundle').querySelector('.bundle_customer_parts')
    cocoonContainer.addEventListener('cocoon:after-insert', (e, insertedItem, originalEvent) => {
      let docTarget = this.typeOfDocumentationTarget
      let selectedOption = docTarget.options[docTarget.selectedIndex];
      if(selectedOption.dataset.partsFields == null) { return; }
      this.displayDocumentationFields(JSON.parse(selectedOption.dataset.partsFields), this.dynamicCustomerPartsTargets)
    });
    
    this.fetchExistingFieldsConfig();
    this.checkIfConfigChanged();
    this.setToNil();
  }

  cancelChanges(){
    this.hiddenCloseBtnTarget.click()
    this.bundleTarget.innerHTML = this.bundleInitialValue;
  }

  storeInitialChanges(){
    this.bundleInitialValue = this.bundleTarget.innerHTML;
  }

  fetchExistingFieldsConfig(){
    let existing_groups_config = this.existingConfigurationTarget.dataset.groupsFields;
    let existing_parts_config = this.existingConfigurationTarget.dataset.partsFields;
    if (existing_groups_config){
      this.displayDocumentationFields(JSON.parse(existing_groups_config), this.dynamicBundleGroupsTargets);
    }
    if(existing_parts_config){
      this.displayDocumentationFields(JSON.parse(existing_parts_config), this.dynamicCustomerPartsTargets);
    }
  }

  setToNil() {
    let persisted = this.typeOfDocumentationTarget.dataset.persisted;

    if(persisted != 'false'){
      if(this.typeOfDocumentationTarget.dataset.docTypeId === undefined){
        this.typeOfDocumentationTarget.value = ''
      }
    }
  }

  checkIfConfigChanged(){
    let docTarget = this.typeOfDocumentationTarget
    let selectedOption = docTarget.options[docTarget.selectedIndex];
    let group_config = {}
    let parts_config = {}
    if(docTarget.value == this.existingConfigurationTarget.dataset.docType){
      group_config = this.existingConfigurationTarget.dataset.groupsFields
      parts_config = this.existingConfigurationTarget.dataset.partsFields
    }else{
      group_config = selectedOption.dataset.groupFields
      parts_config = selectedOption.dataset.partsFields
    }
    this.displayDocumentationFields(JSON.parse(group_config), this.dynamicBundleGroupsTargets);
    this.displayDocumentationFields(JSON.parse(parts_config), this.dynamicCustomerPartsTargets);
  }

  displayDocumentationFields(fieldsConfig, fields){
    if(fieldsConfig.length == 0 || fields.length == 0) { return; }
    fields.forEach((field) => {
      field.classList.add('d-none')
      if(fieldsConfig[field.dataset.identifier] == 'optional'){
        let obligatoryLabel = field.querySelector('label')
        if(obligatoryLabel){
          obligatoryLabel.innerHTML = obligatoryLabel.innerHTML.replace('*','')
        }
        field.classList.remove('d-none');
      }else if(fieldsConfig[field.dataset.identifier] == 'obligatory'){
        let obligatoryLabel = field.querySelector('label')
        if(obligatoryLabel){
          obligatoryLabel.innerHTML = obligatoryLabel.innerHTML.replace('*','')
          const required_indicator = document.createElement("span")
          required_indicator.innerHTML = '*'
          required_indicator.style.textDecoration = 'underline dotted';
          obligatoryLabel.appendChild(required_indicator)
        }
        field.classList.remove('d-none');
        let input = field.querySelector('input')
        if(input) input.required = true
      }
    })
  }

  getRanHex(size) {
    let result = [];
    let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
  
    for (let n = 0; n < size; n++) {
      result.push(hexRef[Math.floor(Math.random() * 16)]);
    }
    return result.join('');
  }

  remove_modal_overlay() {
    this.hiddenCloseBtnTarget.click()
  }

  set_table_row_information() {
    this.rowBundleQtyTarget.textContent = this.bundleQtyTarget.value  + ' ' + this.bundleTypeTarget.options[this.bundleTypeTarget.selectedIndex].textContent + '(s)';
    this.rowBundleQtyTarget.setAttribute('data-total-quantity', this.bundleQtyTarget.value)
    this.rowWeightTarget.textContent = this.bundleWeightTarget.value + ' ' + this.bundleWeightTypeTarget.options[this.bundleWeightTypeTarget.selectedIndex].textContent;    
    let parts = '';
    let numbers = '';
    let total_qty = 0;
    this.bundlePartTargets.forEach((bundlePart, index) => {
      if (bundlePart.closest('.customer_part').style.display != 'none') {
        let qty = bundlePart.closest('.product').querySelector('.product_qty').value;
        parts += bundlePart.options[bundlePart.selectedIndex].textContent + ' - ' + qty + ' UNIT(S)' + '<br>';
        total_qty += parseInt(qty);
      }
    });

    this.bundlePartNumberTargets.forEach((bundle) => {
      if (bundle.closest('.customer_part').style.display == 'none') return
      numbers += bundle.value + '<br>'
    })

    this.rowPartTypeTarget.innerHTML = ''
    let fragment = document.createDocumentFragment();
    this.bundlePartTypeTargets.forEach((bundle) => {
      if (bundle.closest('.customer_part').style.display == 'none') return
      let checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.setAttribute("disabled", "disabled");
      checkbox.checked = bundle.checked;
      fragment.appendChild(checkbox);
      fragment.appendChild(document.createElement("br"));
    })

    total_qty = total_qty * parseInt(this.bundleQtyTarget.value);
    this.rowPiecesQtyTarget.textContent = total_qty.toString() +  ' Piece(s)';
    this.rowPiecesQtyTarget.setAttribute('data-total-quantity', total_qty.toString());
    this.rowPartsTarget.innerHTML = parts;
    this.rowNumbersTarget.innerHTML = numbers;
    this.rowPartTypeTarget.appendChild(fragment);
  }
}